<template>
  <div>
    <component
      :is="currentComponent"
      :batch-operation-record-info="info"
    />
  </div>
</template>

<script>
import { findBatchOperationRecord } from '@/api/batch_operation_record'

export default {
  name: 'ShowBatchOperationRecord',
  components: {
    BatchOperationRecordImportCardsShow: () => import('@/views/batch_operation_records/detail/import_cards/Show'),
    BatchOperationRecordAllocateCardsToTopAgentShow: () => import('@/views/batch_operation_records/detail/allocate_cards_to_top_agent/Show'),
    BatchOperationRecordAllocateCardsToAgencyShow: () => import('@/views/batch_operation_records/detail/allocate_cards_to_agency/Show'),
    BatchOperationRecordRenewShow: () => import('@/views/batch_operation_records/detail/renew/Show'),
    BatchOperationRecordSetUsageLimitShow: () => import('@/views/batch_operation_records/detail/set_usage_limit/Show'),
    BatchOperationRecordCommonShow: () => import('@/views/batch_operation_records/detail/CommonShow'),
    BatchOperationRecordBackToInventoryShow: () => import('@/views/batch_operation_records/detail/back_to_inventory/Show'),
    BatchOperationRecordBatchModifyShow: () => import('@/views/batch_operation_records/detail/batch_modify/Show')
  },
  data() {
    return {
      info: {},
      operation_type: ''
    }
  },
  computed: {
    batchOperationRecordId() {
      return parseInt(this.$route.params.id)
    },

    currentComponent() {
      switch (this.operation_type) {
        case 'import_cards':
          return 'BatchOperationRecordImportCardsShow'
        case 'allocate_cards_to_top_agent':
          return 'BatchOperationRecordAllocateCardsToTopAgentShow'
        case 'allocate_cards_to_agency':
          return 'BatchOperationRecordAllocateCardsToAgencyShow'
        case 'renew':
          return 'BatchOperationRecordRenewShow'
        case 'set_usage_limit':
          return 'BatchOperationRecordSetUsageLimitShow'
        case 'back_to_inventory':
          return 'BatchOperationRecordBackToInventoryShow'
        case 'batch_modify':
          return 'BatchOperationRecordBatchModifyShow'
        default:
          if (this.operation_type !== '') {
            return 'BatchOperationRecordCommonShow'
          }
          return ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findBatchOperationRecord(this.batchOperationRecordId).then((res) => {
        this.info = res.data
        this.operation_type = res.data.operation_type_slug
      })
    }
  }
}
</script>
